import React from 'react';
import { shape, func, string } from 'prop-types';

const mapComponents = (components, spot) => {
  const componentsToRender = [];
  Object.values(components).forEach(value => {
    if (value && value.dynamic_component && value.dynamic_component.spot === spot) {
      componentsToRender.push(value);
    }
  });
  return componentsToRender.sort((a, b) => a.dynamic_component.order - b.dynamic_component.order);
};

const DynamicComponentWrapper = ({
  components,
  renderDynamicComponent,
  spot,
  className,
  runCatchErrorBoundary = () => {},
}) => {
  try {
    const componentsToRender = mapComponents(components, spot);
    const content = componentsToRender.map(({ component_id, id }, index, arr) => (
      <React.Fragment key={`${component_id}-${id}`}>
        {renderDynamicComponent(component_id || id, index, arr.length)}
      </React.Fragment>
    ));
    return <div className={className}>{content}</div>;
  } catch (error) {
    /* istanbul ignore next */
    return runCatchErrorBoundary(error);
  }
};

DynamicComponentWrapper.propTypes = {
  components: shape().isRequired,
  renderDynamicComponent: func,
  spot: string,
  className: string,
  runCatchErrorBoundary: func,
};

export default DynamicComponentWrapper;
